<template>
  <div class="home-wrapper mt-4">
    <div class="flex">
      <div class="lg:w-1/2 w-full text-left flex justify-center p-2">
        <div class="lg:w-2/3 w-full self-center">
          <p class="text-3xl text-blue-400 font-bold">海码编程考试中心</p>
          <p class="mb-4 text-lg text-gray-500 font-bold">Haimakid Examination Center</p>
          <p class="text-gray-500">海码编程考试中心是针对全国青少年等级考试（Youth Level Test 简称：等级考试）推出的考试服务平台，为参加等级考试的考生提供对应等级考试真题、模拟训练、错题分析、在线指导等系列服务。</p>
          <el-button class="mt-4" icon="el-icon-document" type="warning" @click="$router.push('/mock/generate')">开始考试</el-button>
        </div>
      </div>
      <div class="lg:w-1/2 w-full justify-center md:flex hidden py-16">
        <img :src="require('@/assets/home.png')" class="lg:w-3/5 w-full" alt="home-img">
      </div>
    </div>
    <div class="mt-4 flex flex-col items-center bg-gray-100 p-4 rounded">
      <p class="text-2xl pb-2" style="border-bottom: 4px solid #60A5FA">什么是全国青少年等级考试？</p>
      <p class="p-4 lg:w-2/3 w-full text-gray-500">青少年等级考试，英文名称（Youth Level Test）以下简称等级考试。等级考试是中国电子学会服务于国家教育改革和全民科学素质提升的需要，以提升我国青少年整体信息素养和创新能力为目标，于 2011 年起面向全国青少年开展的信息技术能力素质的评价项目。</p>
    </div>
    <div class="mt-4 flex flex-col items-center p-4">
      <p class="text-2xl pb-2" style="border-bottom: 4px solid #60A5FA">等级考试包含哪些考试？</p>
      <div class="flex flex-wrap w-full mt-4">
        <div class="md:w-1/4 w-1/2 text-center p-4" v-for="(icon, iconIndex) of iconList" :key="iconIndex">
          <img class="mx-auto" :src="icon.url" alt="img">
          <p class="p-2">{{ icon.name }}</p>
        </div>
      </div>
    </div>
    <div class="mt-4 flex flex-col items-center p-4">
      <p class="text-2xl pb-2" style="border-bottom: 4px solid #60A5FA">平台提供的服务</p>
      <div class="flex flex-wrap w-full mt-4 py-4">
        <div class="md:w-1/4 w-1/2 md:pr-3 mb:pb-0 pr-2 pb-2">
          <div class="py-10 bg-blue-400 text-white font-bold rounded relative overflow-hidden number number-1">
            <p>等级考试真题</p>
          </div>
        </div>
        <div class="md:w-1/4 w-1/2 md:pr-3 mb:pb-0 pb-2">
          <div class="py-10 bg-yellow-400 text-white font-bold rounded relative overflow-hidden number number-2">
            <p>模拟题目训练</p>
          </div>
        </div>
        <div class="md:w-1/4 w-1/2 md:pr-3 mb:pb-0 pr-2 pb-2">
          <div class="py-10 bg-purple-400 text-white font-bold rounded relative overflow-hidden number number-3">
            <p>错题分析</p>
          </div>
        </div>
        <div class="md:w-1/4 w-1/2 md:pr-3 mb:pb-0 pb-2">
          <div class="py-10 bg-green-400 text-white font-bold rounded relative overflow-hidden number number-4">
            <p>在线指导</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      iconList: [
        { url: require('@/assets/ceit/young_robo_ico.png'), name: '青少年机器人技术等级考试' },
        { url: require('@/assets/ceit/young_soft_design_ico.png'), name: '青少年软件编程等级考试' },
        { url: require('@/assets/ceit/young_3d_design_ico.png'), name: '青少年三维创意设计等级考试' },
        { url: require('@/assets/ceit/young_electron_ico.png'), name: '青少年电子信息等级考试' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.number::before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10rem;
  line-height: 7rem;
  color:rgba($color: #fff, $alpha: 0.2);
}
.number-1::before {
  content: "1";
}
.number-2::before {
  content: "2";
}
.number-3::before {
  content: "3";
}
.number-4::before {
  content: "4";
}
</style>